var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "newdetail-container position-relative" },
    [
      _c("afterSales", { ref: "afterSales" }),
      _c(
        "div",
        {
          staticClass: "fy1 fy2",
          staticStyle: { position: "fixed", bottom: "0", right: "0" },
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }