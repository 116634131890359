<template>
  <div class="newdetail-container position-relative">
    <afterSales ref="afterSales"></afterSales>
    <div class="fy1 fy2" style="position: fixed; bottom: 0; right: 0">
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
import { BASE } from "@/api";
import { Loading } from "element-ui";
import { REVIEW_STATUS_ENUM, AFTER_SHOW_TYPE_ENUM } from "./utils/enum";
import { postAfterSaleStatistics } from "@/api/generalControl/index.js";
import afterSales from "./Aftersales/afterSales.vue";
export default {
  components: {
    afterSales
  },
  data() {
    return {
      REVIEW_STATUS_ENUM,
      AFTER_SHOW_TYPE_ENUM,
      afterLoading: true,
      afterTableData: [],
      aftertype: {
        10: "运损——外包装损坏，如压坏、挤压、漏洞、变形等",
        20: "缺货——到货件数小于购买件数",
        30: "质量问题——坏果、标品漏坏、商品变质、涨袋、破损、过期等的问题",
        40: "货不对板——到货商品与商品描述不符",
        50: "缺斤少两",
        60: "差价",
      },
      merchantList: {},
      afterCompletionStatus: 1, // 售后完成状态
      step: "",
      savebtn: false,
      id: "",
      goodsData: [
        //商品信息
      ],
      loading: true,
      checkForm: {
        after_id: "",
        status: 30,
        amount: "",
        supplier_amount: "",
        remark: "",
      },
    };
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.checkForm.after_id = this.id;
      this.state = this.$route.query.state;
      this.getDetail();
      this.getStep();
      this.postAjaxAfterSaleStatistics();
    }
  },
  methods: {
    // 售后率列表展示
    async postAjaxAfterSaleStatistics() {
      try {
        const res = await postAfterSaleStatistics({
          after_id: this.id,
        });
        this.afterTableData = res?.data || [];
        this.$refs.afterSales.hqinfoafterTableData(this.afterTableData)
      } catch (error) {
        console.log(error, "postAfterSaleStatistics");
      } finally {
        this.afterLoading = false;
      }
    },
    sureConfirm() {
      console.log(this.checkForm);
      this.$confirm("确定审核吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let load = Loading.service({ fullscreen: false });
          this.tool.debounce(() => {
            let form = this.tool.DeepCopy({}, this.checkForm);
            form.status = form.status == 50 ? 30 : form.status;
            this.$api.general
              .aftersalenewHandle(form)
              .then((res) => {
                load.close();
                this.tool.message("审核成功", "success");
                setTimeout(() => {
                  this.$router.back();
                }, 800);
              })
              .catch((error) => {
                load.close();
              });
          });
        })
        .catch(() => {});
    },
    // 获取详情
    getDetail() {
      this.$api.general
        .aftersalenewDetail({ after_id: this.id })
        .then((res) => {
          // this.merchantList = res.data
          this.goodsData = [res.data.aftersale_detail];
          const { aftersale_cond, as_cond } = res.data.aftersale_detail.product;
          let asCond = "";
          if (aftersale_cond?.length) {
            aftersale_cond.map((item) => {
              asCond += `${item.name}；`;
            });
          }
          this.merchantList = res.data;
          this.merchantList.aftersale_detail.product.as_cond =
            asCond || as_cond;
          console.log(this.merchantList);
          this.loading = false;
          this.checkForm.amount = this.tool.toDecimal2(
            (this.merchantList.after.refund_amt * 10000) / 10000 / 100
          );
          this.$refs.afterSales.hqinfogoodsData(this.goodsData)
          this.$refs.afterSales.hqinfomerchantList(this.merchantList)
        });
    },
    // 获取流程
    getStep() {
      this.$api.general.aftersaleStep({ after_id: this.id }).then((res) => {
        this.step = res.data;
        this.$refs.afterSales.hqinfostep(this.step)
      });
    },
  },
};
</script>

<style lang="less">
.newdetail-container {
  .kv {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  .form-box {
    .el-form-item {
      margin-bottom: 0;
    }
  }

  .video {
    width: 230px;
    height: 230px;
  }

  .after-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;

    img {
      object-fit: fill;
    }
  }
  .after-image:last-of-type {
    margin-right: 0;
  }

  .el-step__icon.is-text {
    border: 0;
  }

  .el-step__title.is-wait {
    color: #333333;
  }

  .activeimg {
    width: 16px;
    height: 16px;
  }

  .checkbtn {
    cursor: pointer;
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    margin-right: 10px;
    border: 1px solid #333333;
  }

  .checkbtn:last-of-type {
    margin-right: 0;
  }

  .checkbtn-active {
    background-color: #13ae67;
    border: 1px solid #13ae67;
    color: #ffffff;
  }

  .font {
    font-size: 12px;
  }

  .fy2 {
    left: 250px;
  }

  .pt {
    padding-top: 5px;
  }

  .py {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pb {
    padding-bottom: 5px;
  }

  .border-b {
    border-bottom: 1px solid #ebeef5;
  }

  .video {
    width: 230px;
  }

  .el-input.is-disabled .el-input__inner,
  .el-textarea.is-disabled .el-textarea__inner {
    color: #333333;
  }

  .remark-wrapper {
    padding: 20px 60px;
    border: 1px solid #dbe0e4;
  }

  .input-width {
    width: 200px;
  }
  .fy2 {
    left: 250px;
  }
  .sale-font {
    font-size: 15px;
  }

  padding: 22px 25px 60px;
  background-color: #ffffff;
  box-sizing: border-box;
  min-height: calc(100% - 20px);

  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  // .auto-table-flex .el-table .el-table__footer-wrapper .cell {
  //   color: #13AE67;
  //   font-size: 20px;
  // }
}
</style>
